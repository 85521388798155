import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import {
  Button,
  Container as MuiContainer,
  Typography,
} from "@material-ui/core"
import { Paragraph } from "components-extra"

import { Footer, Layout, Navbar, SEO } from "components"

const Container = styled(MuiContainer)`
  margin-bottom: 32px;
`

const PageTitle = styled(Typography).attrs(() => ({
  variant: "h2",
  color: "textSecondary",
  component: "h1",
}))`
  text-align: center;
  margin: 32px;
`
const Title = styled(Typography).attrs(() => ({
  variant: "h4",
  color: "textSecondary",
}))`
  margin-bottom: 12px;
  margin-top: 24px;
`

const Text = styled(Paragraph).attrs(() => ({
  color: "textPrimary",
  variant: "body1",
}))``

const BackToHome = styled(Button)`
  margin-top: 24px;
`

const Section = ({ children, title }) => (
  <React.Fragment>
    <Title>{title}</Title>
    {children}
  </React.Fragment>
)

const Legal = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
          }
        }
      }
    `
  )

  return (
    <Layout hideMenu>
      <SEO title="Mentions légales" />
      <Navbar hideMenu />
      <Container maxWidth="md">
        <BackToHome to="/" component={Link} variant="contained" color="primary">
          ← retour
        </BackToHome>
        <PageTitle>Mentions Légales</PageTitle>
        <hr />
        <br />
        <Section title="Editeur du site">
          <Text>
            Alexandre Le Lain (
            <a href="mailto:lelain.alexandre@gmail.com">
              lelain.alexandre@gmail.com
            </a>
            )
          </Text>
          <Text>
            <i>Ingénieur études et développement</i>
          </Text>
        </Section>

        <Section title="Hébergeur du site">
          <Text>
            Netlify (<a href="https://www.netlify.com/">netlify.com</a>)
          </Text>
        </Section>

        <Section title="Conditions d'utilisation">
          <Text>
            Le site accessible par l'url suivante :{" "}
            <Link to="/">{siteMetadata.url}</Link>
            {` `} est exploité dans le respect de la législation française.
            L'utilisation de ce site est régie par les présentes conditions
            générales. En utilisant le site, vous reconnaissez avoir pris
            connaissance de ces conditions et les avoir acceptées. Celles-ci
            pourront êtres modifiées à tout moment et sans préavis par l'éditeur
            du site. L'éditeur ne saurait être tenu pour responsable en aucune
            manière d’une mauvaise utilisation du service.
          </Text>
        </Section>

        <Section title="Limitation de responsabilité">
          <Text>
            Les informations contenues sur ce site sont aussi précises que
            possibles et le site est périodiquement remis à jour, mais peut
            toutefois contenir des inexactitudes, des omissions ou des lacunes.
            Si vous constatez une lacune, erreur ou ce qui parait être un
            dysfonctionnement, merci de bien vouloir le signaler par{" "}
            <a href="mailto:lelain.alexandre@gmail.com">email</a> en décrivant
            le problème de la manière la plus précise possible (page posant
            problème, action déclenchante, type d’ordinateur et de navigateur
            utilisé, …).
          </Text>
          <br />
          <Text>Les photos sont non contractuelles.</Text>
          <br />
          <Text>
            Les liens hypertextes mis en place dans le cadre du présent site
            internet en direction d'autres ressources présentes sur le réseau
            Internet ne sauraient engager la responsabilité de l'éditeur du
            site.
          </Text>
        </Section>

        <Section title="Confidentialité">
          <Text>
            Aucune donnée personnelle n'est enregistrée lors de votre visite sur
            le site.
          </Text>
        </Section>

        <Section title="Cookies">
          <Text>
            Aucun cookie n'est enregistré lors de votre visite sur le site.
          </Text>
        </Section>

        <Section title="Liens hypertextes">
          <Text>
            Le site offre des liens vers d’autres sites internet ou d’autres
            ressources disponibles sur Internet.
          </Text>
          <br />
          <Text>
            Il n'y a aucun moyen pour contrôler les sites en connexion avec ses
            sites internet. L'éditeur du site ne répond pas de la disponibilité
            de tels sites et sources externes, ni ne la garantit. Il ne peut
            être tenu pour responsable de tout dommage, de quelque nature que ce
            soit, résultant du contenu de ces sites ou sources externes, et
            notamment des informations, produits ou services qu’ils proposent,
            ou de tout usage qui peut être fait de ces éléments. Les risques
            liés à cette utilisation incombent pleinement à l'internaute, qui
            doit se conformer à leurs conditions d'utilisation.
          </Text>
        </Section>

        <Section title="Responsabilité">
          <Text>
            Aucune autre garantie n'est accordée au client, auquel incombe
            l'obligation de formuler clairement ses besoins et le devoir de
            s'informer. Si des informations fournies par l'éditeur du site
            apparaissent inexactes, il appartiendra au client de procéder
            lui-même à toutes vérifications de la cohérence ou de la
            vraisemblance des résultats obtenus. l'éditeur du site ne sera en
            aucune façon responsable vis à vis des tiers de l'utilisation par le
            client des informations ou de leur absence contenues dans ses
            produits y compris un de ses sites Internet.
          </Text>
        </Section>
        <br />
      </Container>
      <Footer />
    </Layout>
  )
}

export default Legal
